import ReactDOM from 'react-dom';
/* eslint-disable */
// this is a hack to fix missing styles on refresh in production
// reference: https://github.com/gatsbyjs/gatsby/issues/17676#issuecomment-535796737
export const replaceHydrateFunction = () => {
  return (element: any, container: any, callback: any) => {
    ReactDOM.render(element, container, callback);
  };
};
/* eslint-enable */
